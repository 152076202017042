import { Injectable } from "@angular/core";
import moment, { Moment } from "moment";

@Injectable({
  providedIn: "root"
})
export class AppDataService {
  private data: any;

  constructor() {
    this.data = JSON.parse(document.getElementById("app-data").textContent);
  }

  get gitCommitTimestamp(): Moment {
    return moment(this.data.timestamp);
  }

  get gitCommitShortHash(): string {
    return this.data.shortCommitHash;
  }

  get getAppInsightsId(): string {
    return this.data.appInsightsId;
  }

  get keycloakUrl() {
    return this.data.keycloakUrl;
  }

  get keycloakClientId(): string {
    return this.data.keycloakClientId;
  }

  get keycloakRealm(): string {
    return this.data.keycloakRealm;
  }

  get plusUrl(): string {
    return this.data.plusUrl;
  }
}
