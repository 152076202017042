/**
 * A form group that is for an AbstractFieldConfigDtoUnion[].
 * Provides additional access to the field config and sets controls / default values / validation accordingly.
 * Since we use angular observables and those require init/cleanup, this form group needs to be destroyed once not in use any more.
 */
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import {
  AbstractFieldConfigDto,
  AbstractFieldConfigDtoUnion,
  AbstractFieldVisibilityConditionUnion,
  FieldConfigCheckboxDto,
  FieldConfigDateDto,
  FieldConfigInputDto,
  FieldConfigInputAreaDto
} from "connect-frontend-service";
import { debounceTime, combineLatest, distinctUntilChanged, Observable, map, of, shareReplay, Subscription } from "rxjs";
import isEqual from "lodash/isEqual";
import { Adresse, validateKennzeichen } from "connect-frontend-components/inputs";
import moment from "moment";

const assertNever = (type: never) => {
  throw new Error("Unknown type: " + type);
};

const getDefaultValue = (config: AbstractFieldConfigDtoUnion) => {
  if (config.inputType === "adresse") {
    return [Adresse.EMPTY];
  } else if (config.inputType === "input") {
    return "";
  } else if (config.inputType === "time") {
    return null;
  } else if (config.inputType === "input_numeric") {
    return "";
  } else if (config.inputType === "input_big_decimal") {
    return "";
  } else if (config.inputType === "input_area") {
    return "";
  } else if (config.inputType === "date") {
    return null;
  } else if (config.inputType === "checkbox") {
    return config.defaultValue;
  } else if (config.inputType === "headline") {
    return null;
  } else if (config.inputType === "hinweis") {
    return null;
  } else if (config.inputType === "kennzeichen") {
    return { teil1: "", teil2: "", teil3: "" };
  } else if (config.inputType === "combobox") {
    return config.multiple ? [] : null;
  } else if(config.inputType === "list") {
    return null;
  } else {
    assertNever(config);
  }
};

const validator = (config: AbstractFieldConfigDtoUnion) => (control: AbstractControl): ValidationErrors | null => {
  if (!config) {
    // Ignore: race condition, field invisible, ...
    return null;
  } else {
    if (config.required && (!control.value || control.value.length === 0)) {
      return { required: true };
    }
    if (config.inputType === "kennzeichen") {
      return validateKennzeichen(config.required)(control);
    }
    if (config.inputType === "input") {
      if ((config as FieldConfigInputDto).maxLength) {
        const value = control.value as string;
        if (typeof value === "string" && value.length > (config as FieldConfigInputDto).maxLength) {
          return { maxlength: true };
        }
      }
    } else if (config.inputType === "input_area") {
      if ((config as FieldConfigInputAreaDto).maxLength) {
        const value = control.value as string;
        if (typeof value === "string" && value.length > (config as FieldConfigInputAreaDto).maxLength) {
          return { maxlength: true };
        }
      }
    } else if (config.inputType === "date") {
      if ((config as FieldConfigDateDto).maxDate) {
        const value = control.value as string;
        if (moment(value).isAfter(moment((config as FieldConfigDateDto).maxDate))) {
          return { maxdate: true };
        }
      }
      if ((config as FieldConfigDateDto).minDate) {
        const value = control.value as string;
        if (moment(value).isBefore(moment((config as FieldConfigDateDto).minDate))) {
          return { mindate: true };
        }
      }
    } else if (config.inputType === "checkbox") {
      if ((config as FieldConfigCheckboxDto).required) {
        const value = control.value as boolean;
        if (!value.valueOf()) {
          return { required: true };
        }
      }
    }
    return null;
  }
};

const generateControls = (configs: AbstractFieldConfigDtoUnion[]): { [p: string]: AbstractControl } => {
  const controls: { [p: string]: AbstractControl } = {};
  configs.forEach(config => controls[config.key] = new UntypedFormControl(null));
  return controls;
};

/** The predicate that evaluates the visibility conditions */
type VisibilityEvaluator = (condition: AbstractFieldVisibilityConditionUnion) => Observable<boolean>;
/** An association between a form field key and visibility value (true, false) */
type FieldVisibility = Record<string, boolean>;

export class DynamicFormGroup extends UntypedFormGroup {

  public visibleFields$: Observable<AbstractFieldConfigDtoUnion[]>;
  private subscriptions: Subscription = new Subscription();

  /**
   * Constructor.
   *
   * @param configs The form field's meta information (containing their visibility conditions)
   * @param visibilityConditionEvaluator <b>Optional</b> function implementing of {@link VisibilityEvaluator} that
   * evaluates the fields' visibility conditions. Note that it defaults to true or always visible if no visibility
   * evaluator was passed.
   */
  constructor(
      public readonly configs: AbstractFieldConfigDtoUnion[],
      private visibilityConditionEvaluator: VisibilityEvaluator = () => of(true)
  ) {
    super(generateControls(configs));

    // A Map: field key → visible
    const fieldVisibility$: Observable<FieldVisibility> =
      combineLatest(
        configs.map(config => this.isVisible(config).pipe(
          map(visible => ({ [config.key]: visible }))
        ))
      ).pipe(
        map((visibilities: FieldVisibility[]) => visibilities.reduce((a, b) => ({ ...a, ...b }), {})),
        shareReplay(1),
        // This is very important to avoid infinite loops.
        distinctUntilChanged(isEqual)
      );

    this.visibleFields$ = fieldVisibility$.pipe(
      map((fieldVisibility: FieldVisibility) => configs.filter(c => fieldVisibility[c.key])),
      shareReplay(1),
      // Small timeout to wait tor fieldVisibility$ to settle.
      debounceTime(1),
      distinctUntilChanged((a, b) => isEqual(a.map(it => it.key), b.map(it => it.key)))
    );

    // Remove invisible field values, set visible field values to defaults
    this.subscriptions.add(
      // fieldVisibility$.subscribe(fieldVisibility =>  this.updateFieldVisibilities(fieldVisibility))
      fieldVisibility$.pipe(
        debounceTime(20)
      ).subscribe(fieldVisibility =>  this.updateFieldVisibilities(fieldVisibility))
    );
  }

  /**
   * Call this method once the dynamic group is not needed any more, to clean up all state
   */
  public destroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Re-evaluates the visibility of each field
   *
   * @param fieldVisibility The current map of which fields are visible
   */
  private updateFieldVisibilities(fieldVisibility: Record<string, boolean>) {
    // Update field visibility => set their value to null or default.
    const value = this.computeValuesAfterVisibilityUpdate(fieldVisibility);
    this.setValue(value);

    // Now we trigger an update on the validation.
    this.configs.forEach(config => {
      const control = this.get(config.key);
      // Invisible fields don't get a validator.
      control.setValidators(fieldVisibility[config.key] ? [validator(config)] : []);
      control.updateValueAndValidity({ emitEvent: true });
    });
  }


  private computeValuesAfterVisibilityUpdate(fieldVisibility: Record<string, boolean>) {
    const value = { ...(this.value as Record<string, unknown>) };
    this.configs.forEach(config => {
      if (fieldVisibility[config.key]) {
        // Field might have shown enabled => if it is null, set to default
        const defaultValue = getDefaultValue(config);
        if (!(config.key in value) || (defaultValue !== null && value[config.key] === null)) {
          value[config.key] = defaultValue;
        }
      } else {
        // Field might have been hidden => Invisible fields are always 'null'
        if (value[config.key] !== null) {
          value[config.key] = null;
        }
      }
    });
    return value;
  }

  /**
   * Evaluates the visibility state of a field using the associated collection of visibility conditions
   * {@link AbstractFieldVisibilityConditionUnion}.
   *
   * @param config The field's meta information
   * @return true if each of the visibility conditions evaluates to true, false otherwise
   * */
  private isVisible(config: AbstractFieldConfigDto): Observable<boolean> {
    return combineLatest(
      config.visibilityConditions.map(condition => this.visibilityConditionEvaluator(condition))
    ).pipe(
      map((visibilities: boolean[]) => visibilities.every(visibility => visibility))
    );
  }
}
