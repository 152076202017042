import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddressInputModule, DatePickerFieldModule, FmxDropdownModule, KennzeichenInputModule, OptionTilesModule } from "connect-frontend-components/inputs";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { EditorMatFormControlModule } from "connect-frontend-components/editor";
import { EditorModule } from "@tinymce/tinymce-angular";
import { OptionsFilterModule } from "connect-frontend-components/inputs";
import { MatIconModule } from "@angular/material/icon";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ScrollingModule as ExperimentalScrollingModule } from "@angular/cdk-experimental/scrolling";
import { FieldsComponent } from "./fields.component";
import { FieldInputComponent } from "./field-input/field-input.component";
import { FieldKennzeichenComponent } from "./field-kennzeichen/field-kennzeichen.component";
import { FieldCheckboxComponent } from "./field-checkbox/field-checkbox.component";
import { FieldHeadlineComponent } from "./field-headline/field-headline.component";
import { FieldDateComponent } from "./field-date/field-date.component";
import { FieldAdresseComponent } from "./field-adresse/field-adresse.component";
import { FieldComboboxComponent } from "./field-combobox/field-combobox.component";
import { FieldHinweisComponent } from "./field-hinweis/field-hinweis.component";
import { FieldInputNumericComponent } from "./field-input-numeric/field-input-numeric.component";
import { FieldInputAreaComponent } from "./field-input-area/field-input-area.component";
import { NumbersOnlyDirective } from "./numbers-only.directive";
import { FieldInputBigDecimalComponent } from "./field-input-big-decimal/field-input-big-decimal.component";
import { FieldComponent } from "./field/field.component";
import { FieldTimeComponent } from "./field-time/field-time.component";
import { FieldOptionTilesComponent } from "./field-option-tiles/field-option-tiles.component";


@NgModule({
  declarations: [
    FieldsComponent,
    FieldInputComponent,
    FieldKennzeichenComponent,
    FieldCheckboxComponent,
    FieldHeadlineComponent,
    FieldDateComponent,
    FieldAdresseComponent,
    FieldComboboxComponent,
    FieldHinweisComponent,
    FieldInputNumericComponent,
    FieldInputAreaComponent,
    NumbersOnlyDirective,
    FieldInputBigDecimalComponent,
    FieldComponent,
    FieldTimeComponent,
    FieldOptionTilesComponent
  ],
  exports: [
    FieldsComponent,
    NumbersOnlyDirective,
    FieldInputBigDecimalComponent
  ],
  imports: [
    AddressInputModule,
    CommonModule,
    DatePickerFieldModule,
    EditorMatFormControlModule,
    EditorModule,
    FlexLayoutModule,
    FmxDropdownModule,
    KennzeichenInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    OptionsFilterModule,
    ReactiveFormsModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    TranslateModule,
    OptionTilesModule
  ]
})
export class FieldsModule {
}
