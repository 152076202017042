import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, ValidationErrors, ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { ValidatorService } from "angular-iban";
import {
  combineLatest,
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
  startWith,
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  forkJoin,
  combineLatestWith
} from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractFieldConfigDtoUnion,
  AbstractFieldVisibilityConditionUnion,
  AbstractSchadenmeldungFormularTargetDtoUnion,
  BeschreibungDto,
  CaptchaDto,
  CaptchaService,
  CustomErrorService,
  FieldVisibilityConditionSchadenartDto,
  FirmaDto,
  IAdresse,
  IdBezeichnungDto,
  SchadenmeldungDto,
  SchadenmeldungAntwortDto,
  SchadenmeldungFormularDto,
  SchadenmeldungService,
  SchadenGruppeDto,
  SchadenMeldungAllgemeineDatenDto,
  IdBezeichnungWithRefDto,
  FieldVisibilityConditionSchadentypDto
} from "connect-frontend-service";
import { DynamicFormGroup } from "connect-frontend-components/fields";
import { OptionDialogService } from "connect-frontend-components/option-dialog";
import { DropdownOption, Option, TileOption } from "connect-frontend-components/inputs";
import moment from "moment/moment";
import { AbstractSchadenMeldungFunction } from "./AbstractSchadenMeldungFunction";
import {
  ALLGEMEINE_DATEN,
  ALLGEMEINE_FRAGEN,
  ANSPRECHPARTNER_DATEN,
  BANKDATEN,
  BESCHREIBUNG,
  CAPTCHA_SOLUTION_DATA,
  CAPTCHAREQUESTID,
  DATENSCHUTZSEITE,
  KUNDENNUMMER,
  PROPOSEDSOLUTION,
  SCHADEN_ART_IDS,
  SCHADEN_TARGET,
  SCHADEN_TYP,
  SPEZIFISCHE_DATEN,
  TOKEN,
  VERSICHERUNGS_NEHMER,
  UNTERLAGEN, TENANT_FELDER,
  VALID,
  INVALID
} from "./constants";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { IdBezeichnungWithParentDtoAndIndentation, IndicatorStep, Unterlagen } from "./interfaces";

type ConditionType = "always" | "schadenart" | "hasValue" | "schadentyp" | "not";
/**
 * The value object of a form group (composed of form control name to value pairs). For example:
 * <pre>
 *   { "firstName": "Simon", "lastName": "Dark", "score": 42, "dateOfBirth": 04.10.1957 }
 * </pre>
 */
type FormGroupValue = Record<string, unknown>;
type FieldConfig = AbstractFieldConfigDtoUnion;
type Target = AbstractSchadenmeldungFormularTargetDtoUnion;

@Component({
  selector: "lib-schaden-meldung",
  templateUrl: "./schaden-meldung.component.html",
  styleUrls: ["./schaden-meldung.component.scss"],
})
export class SchadenMeldungComponent extends AbstractSchadenMeldungFunction implements OnInit, OnDestroy {

  @ViewChild("formStepWrapper") formStepWrapper: ElementRef;
  @ViewChild("allgemeineDatenStep") allgemeineDatenStep: ElementRef;
  @ViewChild("versicherungsNehmerStep") versicherungsNehmerStep: ElementRef;
  @ViewChild("spezifischeDatenStep") spezifischeDatenStep: ElementRef;
  @ViewChild("beschreibungStep") beschreibungStep: ElementRef;
  @ViewChild("unterlagenStep") unterlagenStep: ElementRef;
  @ViewChild("bankDatenStep") bankDatenStep: ElementRef;
  @ViewChild("ansprechpartnerDatenStep") ansprechpartnerDatenStep: ElementRef;
  @ViewChild("tenantFieldsStep") tenantFieldsStep: ElementRef;
  @ViewChild("datenschutzSeiteStep") datenschutzSeiteStep: ElementRef;
  @ViewChild("sendenStep") sendenStep: ElementRef;

  @Input() isAnonymusUser = true;
  @Input() dezentralUuid: string;

  /**
   * The Basic data. Warning: This component cannot handle changes to this property, so it should only be set once before init.
   */
  @Input() allgemeineDaten: SchadenMeldungAllgemeineDatenDto;
  @Output() sendingResponse = new EventEmitter<SchadenmeldungAntwortDto>();

  public availableKunden: DropdownOption[];
  public availableOus: DropdownOption[];
  public availableLzrs: DropdownOption[];
  public selectedSchadenTyp: IdBezeichnungWithRefDto = null;
  public indicatorSteps: IndicatorStep[] = [];
  public activeStep: number = null;
  public errorStep: number = null;
  public filesLength = 0;
  public appearance: MatFormFieldAppearance = "outline";
  public spezifischeDatenVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bankdatenVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public beschreibungVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public unterlagenVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ansprechpartnerVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public datenschutzVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public vnVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public tenantfieldVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showButtons$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public captchaRequired = false;
  public captchaImagePath: SafeResourceUrl;

  /** The root of the form hierarchy (master form group). */
  public schadenMeldungFormGroup: UntypedFormGroup;
  // Sub groups / controls
  public allgemeineDatenFormGroup: UntypedFormGroup;
  /** The general questions part of the form. They logically do not belong to any particular claim type */
  public allgemeineFragenFormGroup: UntypedFormGroup;
  public schadenHoeheValues: IdBezeichnungDto[] = [];

  /** This is the main data structure containing all field configs */
  private schadenFormular: SchadenmeldungFormularDto;
  private schadenTypen$: Observable<IdBezeichnungWithRefDto>;
  private selSchadenTypId: string = null;
  private selSchadenArtId: string = null;

  // Initialisation order is important here
  // We need those observables to determine field visibility and to create the form controls.
  // But we need the form control values for those observables.
  // This is why we use a BehaviourSubject to make the observables only use "real" values once the form was loaded.
  private spezifischeDatenGroup$ = new BehaviorSubject<DynamicFormGroup | null>(null);
  private tenantFragenGroup$ = new BehaviorSubject<DynamicFormGroup | null>(null);

  /**
   * An observable that emits the value of the form group identified by {@link SPEZIFISCHE_DATEN}. Note that the value
   * is an object that maps a form control name to a value.
   */
  private spezifischeDatenValue$: Observable<Record<string, unknown>>;
  private tenantFragenValue$: Observable<Record<string, unknown>>;

  // Form controls
  private schadenTypenControl: FormControl<IdBezeichnungWithRefDto> = new FormControl(null, [
    Validators.required,
  ]);
  private versNehmerCtrl: FormControl<IAdresse[]> = null;
  private kundenNummerCtrl: FormControl<string> = null;
  private unterlagenFormGroup: FormGroup<Unterlagen> = null;

  private subscriptions: Subscription = new Subscription();

  private validOnce = false;

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private schadenmeldungService: SchadenmeldungService,
    private captchaService: CaptchaService,
    private sanitizer: DomSanitizer,
    private errorService: CustomErrorService,
    private optionDialogService: OptionDialogService,
    protected translate: TranslateService
  ) {
    super(translate);
  }

  ngOnInit(): void {
    // This cannot be done inside the constructor because input-properties (isAnonymusUser) ain't available there
    // Create an array of observables where each observable resolves a label for a i18n key
    const stepLabels: Observable<string>[] =
      Array.from({ length: 7 }, (_, index: number) => this.translate.get(`schadenmeldung.progressbar.step${index + 1}`));
    // When all observables complete, emit the last emitted value from each preserving the order of the source observables.
    forkJoin(stepLabels).pipe(
      combineLatestWith(this.tenantfieldVisible$) // check wether there are tenant fields
    ).subscribe(
      ([keys, hasTenantFields]) => {
        this.indicatorSteps = [
          { id: 1, label: keys[0], error: false, checked: false, active: true, visible: true }, // Schadentyp
          { id: 2, label: keys[1], error: false, checked: false, active: false, visible: true }, // Schadeninfos
          { id: 3, label: keys[3], error: false, checked: false, active: false, visible: true }, // Unterlagen
          { id: 4, label: keys[2], error: false, checked: false, active: false, visible: (!this.isAnonymusUser && hasTenantFields) || this.dezentralUuid !== undefined }, // Individuelle Felder
          { id: 5, label: keys[4], error: false, checked: false, active: false, visible: this.isAnonymusUser && !this.dezentralUuid }, // Kundendaten
          { id: 6, label: keys[5], error: false, checked: false, active: false, visible: this.isAnonymusUser }, // Ansprechpartner
          { id: 7, label: keys[6], error: false, checked: false, active: false, visible: this.isAnonymusUser } // Datenschutz
        ];
      }
    );

    if (this.target?.type === "kunde") {
      this.availableKunden = this.target.kunden.map(it => ({
        label: it.kundennummer + " | " + it.bezeichnung,
        value: it.id
      }));
    }

    if (this.target?.type === "lzr") {
      this.availableLzrs = this.createOptions(this.target.lzrs);
    }

    if (this.target?.type === "ou") {
      const ous = this.target.ous as IdBezeichnungWithParentDtoAndIndentation[];
      // pretend tree like structure by indentation ...
      const rawOusWithIndentation = ous.map((element) => ({
        ...element,
        indentation: this.createIndentation(ous, element, 0)
      }));
      // sort items by parent-child relationship to make sure children are displayed underneath its' parents
      this.availableOus = this.sortChildren(rawOusWithIndentation).map(it => ({
        label: this.indent(it.indentation) + it.bezeichnung
          + (!it.aktiv ? " [" + this.translate.instant("schadenmeldung.ou_disabled") + "]" : ""),
        disabled: !it.aktiv,
        value: it.id
      }));
    }

    // Need this for custom field visibility => set it up before the rest of the form.
    this.schadenTypen$ = this.schadenTypenControl.valueChanges.pipe(
      startWith(this.schadenTypenControl.value),
      distinctUntilChanged(),
      shareReplay(1),
    );

    // Within the view 'selSchadenTyp' is used as an object containing 'id' and 'foreignId'. However, this object
    // must be remapped back to its 'id' when interacting with the server since it only accepts 'id' as a string and
    // cannot process the complete 'schadenTyp' object. This was done for backward compatibility. Future server
    // implementations can allow the processing of a 'schadenTyp' object
    this.subscriptions.add(
      this.schadenTypen$.subscribe((value: IdBezeichnungWithRefDto) => {
        this.selSchadenArtId = this.getSelSchadenArtId(value);
        this.selSchadenTypId = this.getSelSchadenTypId(value);
      })
    );

    this.spezifischeDatenValue$ = this.spezifischeDatenGroup$.pipe(
      mergeMap((formGroup: DynamicFormGroup) => {
        if (formGroup) {
          return formGroup.valueChanges.pipe(
            startWith(formGroup.value),
            shareReplay(1)
          );
        } else {
          // spezifischeDaten not initialized yet => use a fake value
          return of({});
        }
      })
    );

    this.tenantFragenValue$ = this.tenantFragenGroup$.pipe(
      mergeMap((tenantFragen: DynamicFormGroup) => {
        if (tenantFragen) {
          return tenantFragen.valueChanges.pipe(
            startWith(tenantFragen.value),
            shareReplay(1)
          );
        } else {
          // spezifischeDaten not initialized yet => use a fake value
          return of({});
        }
      })
    );

    // Now that all observables are set up, we can create the full form.
    this.createForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getFormFieldErrorMessageComponent(ctrlName: string): string {
    const group = this.schadenMeldungFormGroup.get(ctrlName);
    return this.getFormFieldErrorMessage(group.errors);
  }

  private sortChildren(arr: any[], pid = null, result = []): any[] {
    arr.forEach(el => {
      if (el.parentId === pid) {
        result.push(el);
        this.sortChildren(arr, el.id, result);
      }
    });
    return result;
  }

  private createIndentation(items, el, i): IdBezeichnungWithParentDtoAndIndentation[] {
    if (!el.parentId) {
      return i;
    }
    return this.createIndentation(items, items.find(p => p.id === el.parentId), i + 1);
  }

  private indent(num: number) {
    return "\t".repeat(num);
  }

  private createOptions(labeledIds: IdBezeichnungDto[]): Option[] {
    if (labeledIds) {
      return labeledIds.map(
        (labeledId: IdBezeichnungDto) => new TileOption(labeledId.bezeichnung, labeledId.id)
      );
    }
    return [];
  }

  // maybe useful in the future if we need to convert to real tree structure of OUs
  // private createTree(items, id = null, link = "parentId") {
  //   const retVal = items
  //     .filter(item => item[link] === id)
  //     .map(item => ({ ...item, children: this.createTree(items, item.id) }));
  //   return retVal;
  // }

  get firma(): FirmaDto {
    return this.allgemeineDaten.firma;
  }

  get target(): Target {
    return this.allgemeineDaten.target;
  }

  get allgemeineFragen(): FieldConfig[] {
    const allgemeineFragen: FieldConfig[] = this.allgemeineDaten?.allgemeineFragen;
    allgemeineFragen.forEach((f: FieldConfig) => (f.key = this.cutKey(f.key)));
    return allgemeineFragen;
  }

  get schadenGruppen(): SchadenGruppeDto[] {
    return this.allgemeineDaten.schadenGruppen;
  }

  get spezielleFragenConfig(): FieldConfig[] {
    return this.schadenFormular?.spezielleFragenConfig;
  }

  get bankdatenConfig() {
    const bankverbindungFragenConfig = this.schadenFormular?.bankverbindungFragenConfig;
    bankverbindungFragenConfig.forEach((f) => (f.key = this.cutKey(f.key)));
    return bankverbindungFragenConfig;
  }

  get tenantFieldsConfig() {
    const customFragenConfig = this.schadenFormular?.customFragenConfig;
    customFragenConfig.forEach((f) => (f.key = this.cutKey(f.key)));
    return customFragenConfig;
  }

  get beschreibungConfig() {
    const beschreibungFragenConfig = this.schadenFormular?.beschreibungFragenConfig;
    beschreibungFragenConfig.forEach((f) => (f.key = this.cutKey(f.key)));
    return beschreibungFragenConfig;
  }

  get datenschutzConfig(): FieldConfig[] {
    const datenschutzFragenConfigs: FieldConfig[] = this.schadenFormular?.datenschutzFragenConfig;
    datenschutzFragenConfigs.forEach((f) => (f.key = this.cutKey(f.key)));
    return datenschutzFragenConfigs;
  }

  private cutKey(key: string): string {
    return key.substring(key.indexOf("_") + 1);
  }

  updateForm(schadenTyp: IdBezeichnungWithRefDto) {
    this.selectedSchadenTyp = schadenTyp;
    // we save this id as temporary value to check if the user wants to change the schadenTyp and display warning
    const schadenTypId: string = this.getSelSchadenTypId(this.selectedSchadenTyp);
    const sub: Subscription = this.schadenmeldungService.getFormularFragen(schadenTypId, this.dezentralUuid ?? "").subscribe({
      next: (value: SchadenmeldungFormularDto) => {
        this.schadenFormular = value;

        this.schadenMeldungFormGroup.addControl(
          SPEZIFISCHE_DATEN,
          new DynamicFormGroup(this.spezielleFragenConfig, (condition) =>
            this.visibilityConditionEvaluator(condition, false)
          )
        );

        this.schadenMeldungFormGroup.addControl(UNTERLAGEN, this.getUnterlagenCtl());
        this.schadenMeldungFormGroup.addControl("bankdaten", new DynamicFormGroup(this.bankdatenConfig));
        if (this.schadenMeldungFormGroup.get("bankdaten").get("iban") !== null) {
          this.setIbanValidation();
        }

        this.schadenMeldungFormGroup.addControl(
          TENANT_FELDER,
          new DynamicFormGroup(this.tenantFieldsConfig, (condition) =>
            this.visibilityConditionEvaluator(condition, true)
          )
        );

        this.unterlagenVisible$.next(true);
        this.bankdatenVisible$.next(value.bankverbindungFragenConfig?.length > 0);
        this.beschreibungVisible$.next(value.beschreibungFragenConfig?.length > 0);
        this.spezifischeDatenVisible$.next(value.spezielleFragenConfig?.length > 0);
        this.tenantfieldVisible$.next(value.customFragenConfig?.length > 0);
      },
      complete: () => {
        this.spezifischeDatenGroup$.next(this.schadenMeldungFormGroup.get(SPEZIFISCHE_DATEN) as DynamicFormGroup);
        this.tenantFragenGroup$.next(this.schadenMeldungFormGroup.get(TENANT_FELDER) as DynamicFormGroup);

        if (this.isAnonymusUser) {
          this.schadenMeldungFormGroup.addControl(DATENSCHUTZSEITE, this.getDatenschutzFormGroup());
          this.allgemeineDatenFormGroup.addControl(KUNDENNUMMER, this.getKundenNummerCtrl());
          if (!this.dezentralUuid) {
            this.allgemeineDatenFormGroup.addControl(VERSICHERUNGS_NEHMER, this.getVersicherungsnehmerCtrl()); // otherwise schadenMeldungFormGroup will never be valid !
            this.vnVisible$.next(this.schadenFormular.versicherungsnehmerFragenConfig?.length > 0);
          }
          this.schadenMeldungFormGroup.addControl(ANSPRECHPARTNER_DATEN, this.getAnsprechpartnerDatenFormGroup());
          this.ansprechpartnerVisible$.next(this.schadenFormular.ansprechpartnerFragenConfig?.length > 0);
          this.datenschutzVisible$.next(this.schadenFormular.datenschutzFragenConfig?.length > 0);
          this.showButtons$.next(true);

          // since theres no next button to trigger this, we do it here - if the user has filled out all datenschutz fields, we activate the indicatorStep
          this.subscriptions.add(
            this.datenschutzVisible$.subscribe((value) => {
              if (value) {
                const ds = this.schadenMeldungFormGroup.get(DATENSCHUTZSEITE).get("datenschutz");
                const vg = this.schadenMeldungFormGroup.get(DATENSCHUTZSEITE).get("vertragsgesetz");
                combineLatest([
                  ds.valueChanges.pipe(startWith(false), distinctUntilChanged()),
                  vg.valueChanges.pipe(startWith(false), distinctUntilChanged())
                ]).pipe(
                  distinctUntilChanged(),
                  shareReplay(1))
                  .subscribe(([dsValue, vgValue]) => {
                    if (dsValue && vgValue) {
                      this.activateIndicatorStep(7);
                    } else {
                      this.errorIndicatorStep(7);
                    }
                  });
              }
            })
          );
        }

        // validate spezifischeDaten step
        this.subscriptions.add(
          combineLatest([
            this.schadenMeldungFormGroup.get("allgemeineFragen").get("schadenDatum").statusChanges,
            this.schadenMeldungFormGroup.get("allgemeineDaten").get("target").statusChanges.pipe(startWith(VALID)),
            this.schadenMeldungFormGroup.get("spezifischeDaten").statusChanges,
            this.schadenMeldungFormGroup.get("bankdaten")?.statusChanges.pipe(startWith(VALID)),
          ]).subscribe(
            ([
              datumStatus,
              targetStatus,
              beschreibungStatus,
              bankStatus
            ]) => {
              if (datumStatus === INVALID || targetStatus === INVALID || beschreibungStatus === INVALID || bankStatus === INVALID) {
                this.errorIndicatorStep(2);
              }
              if (datumStatus === VALID && targetStatus === VALID && beschreibungStatus === VALID && bankStatus === VALID) {
                if (this.indicatorSteps.find(step => step.id === 2).error) {
                  this.activateIndicatorStep(2);
                }
              }
            }
          )
        );

        this.schadenMeldungFormGroup.get("allgemeineFragen").get("schadenDatum").valueChanges.subscribe((value) => {
          if (moment(value).isAfter(moment())) {
            this.schadenMeldungFormGroup.get("allgemeineFragen").get("schadenDatum").setErrors({ dateabovemax: true });
          } else if (moment(value).isBefore(moment("01 Jan 1900"))) {
            this.schadenMeldungFormGroup.get("allgemeineFragen").get("schadenDatum").setErrors({ datebelowmin: true });
          } else {
            this.schadenMeldungFormGroup.get("allgemeineFragen").get("schadenDatum").setErrors(null);
          }
        });

        // validate tenatfield step
        this.subscriptions.add(
          this.schadenMeldungFormGroup.get(TENANT_FELDER)?.statusChanges.subscribe(
            (status) => {
              if (status === INVALID) {
                this.errorIndicatorStep(4);
              }
              if (status === VALID) {
                if (this.indicatorSteps.find(step => step.id === 4).error || !this.hasNextStep()) {
                  this.activateIndicatorStep(4);
                }
              }
            }
          )
        );

        // validate Kundendaten step
        if (!this.dezentralUuid) {
          const kundenDatenControl = this.schadenMeldungFormGroup.get("allgemeineDaten")?.get("versicherungsNehmer");
          const kundenNummerControl = this.schadenMeldungFormGroup.get("allgemeineDaten")?.get("kundenNummer");
          if (kundenDatenControl) {
            this.subscriptions.add(
              kundenDatenControl.statusChanges.pipe(
                startWith(VALID),
                combineLatestWith(kundenNummerControl.statusChanges.pipe(startWith(VALID))),
              ).subscribe(([knStatus, kdStatus]) => {
                if (knStatus === INVALID || kdStatus === INVALID) {
                  this.errorIndicatorStep(5);
                }
                if (knStatus === VALID && kdStatus === VALID) {
                  if (this.indicatorSteps.find(step => step.id === 5).error) {
                    this.activateIndicatorStep(5);
                  }
                }
              })
            );
          }
        }

        // validate Ansprechpartner step
        const ansprechPartnerControl = this.schadenMeldungFormGroup.get("ansprechpartnerDaten")?.get("ansprechpartner");
        if (ansprechPartnerControl) {
          this.subscriptions.add(
            ansprechPartnerControl.statusChanges.subscribe(
              (anStatus) => {
                if (anStatus === INVALID) {
                  this.errorIndicatorStep(6);
                }
                if (anStatus === VALID) {
                  if (this.indicatorSteps.find(step => step.id === 6).error) {
                    this.activateIndicatorStep(6);
                  }
                }
              }
            )
          );
        }

        setTimeout(() => {
          this.activateIndicatorStep(1); // check indicatorStep "Schadentyp", we do this here because there's no next button to trigger this
          // display whatever step after "allgemeineDaten" is next ...
          this.displayNextStep(null);
        }, 0);
      },
    });

    this.subscriptions.add(sub);
  }

  public stepClicked(stepId: number) {
    let stepToGo = null;
    if (stepId === 1) {
      stepToGo = this.allgemeineDatenStep.nativeElement as HTMLElement;
    }
    if (stepId === 2) {
      stepToGo = this.spezifischeDatenStep.nativeElement as HTMLElement;
    }
    if (stepId === 3) {
      stepToGo = this.unterlagenStep.nativeElement as HTMLElement;
    }
    if (stepId === 4) {
      stepToGo = this.tenantFieldsStep.nativeElement as HTMLElement;
    }
    if (stepId === 5) {
      stepToGo = this.versicherungsNehmerStep.nativeElement as HTMLElement;
    }
    if (stepId === 6) {
      stepToGo = this.ansprechpartnerDatenStep.nativeElement as HTMLElement;
    }
    if (stepId === 7) {
      stepToGo = this.datenschutzSeiteStep.nativeElement as HTMLElement;
    }
    stepToGo.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  public activateIndicatorStep(stepId: number) {
    if (stepId === null) {
      this.indicatorSteps.forEach((step) => {
        step.active = false;
        step.checked = false;
      });
      this.indicatorSteps[0].active = true;
      this.activeStep = 1;
      return;
    }

    const visibleSteps = this.indicatorSteps.filter((step) => step.visible);
    const currentIndex = visibleSteps.findIndex((step) => step.id === stepId);
    if (currentIndex > -1) {
      // mark current+1 step as active
      visibleSteps[currentIndex].checked = true;
      visibleSteps[currentIndex].error = false;
      if ((currentIndex + 1) < visibleSteps.length) {
        visibleSteps[currentIndex + 1].active = true;
      }
    }
    this.activeStep = stepId;
  }

  public errorIndicatorStep(stepId: number) {
    if (stepId !== null) {
      const found = this.indicatorSteps.find((step) => step.id === stepId && step.checked);
      if (found) {
        found.error = true;
        this.errorStep = stepId;
      }
      return;
    }
  }

  public displayNextStep(event: MouseEvent | null) {
    const hiddenSteps: NodeList = document.querySelectorAll(
      ".formStep:not(.visible)"
    );
    const nextStep = hiddenSteps.item(0) as HTMLElement;
    if (nextStep) {

      nextStep.classList.add("visible");
      setTimeout(() => {
        nextStep.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 150);

      if (event) {
        // once pressed, remove button
        (event.target as HTMLButtonElement).parentElement.remove();
      }
    }
  }

  public hasNextStep() {
    return document.querySelectorAll(".formStep:not(.visible)").length > 0;
  }

  public hideAllSteps() {
    const allSteps: NodeList = document.querySelectorAll(".formStep");
    allSteps.forEach((step) => {
      (step as HTMLElement).classList.remove("visible");
    });
    setTimeout(() => {
      this.formStepWrapper.nativeElement.scrollTop = 0;
    }, 0);
  }

  setSchadenTyp(schadenTyp: IdBezeichnungWithRefDto): void {
    this.hideAllSteps();
    this.bankdatenVisible$.next(false);
    this.beschreibungVisible$.next(false);
    this.unterlagenVisible$.next(false);
    this.spezifischeDatenVisible$.next(false);
    this.vnVisible$.next(false);
    this.datenschutzVisible$.next(false);
    this.ansprechpartnerVisible$.next(false);
    this.tenantfieldVisible$.next(false);
    this.showButtons$.next(false);


    const spezifischeDaten: DynamicFormGroup = this.spezielleFragenFormGroup;
    if (spezifischeDaten) {
      spezifischeDaten.destroy();
      this.schadenMeldungFormGroup.removeControl(SPEZIFISCHE_DATEN);
    }

    const tenantFields: DynamicFormGroup = this.schadenMeldungFormGroup.get(TENANT_FELDER) as DynamicFormGroup;
    if (tenantFields) {
      tenantFields.destroy();
      this.schadenMeldungFormGroup.removeControl(TENANT_FELDER);
    }

    this.schadenMeldungFormGroup.removeControl("beschreibung");
    this.schadenMeldungFormGroup.removeControl(UNTERLAGEN);
    this.schadenMeldungFormGroup.removeControl("bankdaten");
    this.schadenMeldungFormGroup.removeControl(DATENSCHUTZSEITE);
    this.schadenMeldungFormGroup.removeControl(ANSPRECHPARTNER_DATEN);

    this.resetControls();

    if (schadenTyp !== null) {
      this.updateForm(schadenTyp);
    }
    this.validOnce = false;
  }

  private resetControls() {
    const alD = this.schadenMeldungFormGroup.get("allgemeineDaten");
    const target = alD?.get("target");

    alD.get("kundenNummer")?.reset();
    target?.get("kundeId")?.reset();
    target?.get("pwLzrId")?.reset();
    target?.get("pwOuId")?.reset();

    this.schadenMeldungFormGroup.get("allgemeineFragen").reset();
    this.unterlagenFormGroup?.reset();

    this.versNehmerCtrl?.reset();

  }

  private createForm(): void {
    this.allgemeineFragenFormGroup = new DynamicFormGroup(this.allgemeineFragen);
    this.allgemeineDatenFormGroup = new UntypedFormGroup({
      [SCHADEN_TARGET]: this.getTargetFormGroup(),
      [SCHADEN_TYP]: this.schadenTypenControl,
    });

    this.subscriptions.add(
      this.schadenTypenControl.valueChanges.pipe().subscribe((value) => {
        if (value !== null && this.selectedSchadenTyp === null) {
          this.setSchadenTyp(value);
        } else {
          this.openWarnDialog();
        }
      })
    );

    const captchaFormGroup = new UntypedFormGroup({
      captchaRequestId: new UntypedFormControl(null, Validators.required),
      proposedSolution: new UntypedFormControl(null, Validators.required),
      token: new UntypedFormControl(null, Validators.required),
    });
    captchaFormGroup.disable(); // Captcha nur nach server request notwendig

    this.schadenMeldungFormGroup = new UntypedFormGroup({
      [ALLGEMEINE_DATEN]: this.allgemeineDatenFormGroup,
      [ALLGEMEINE_FRAGEN]: this.allgemeineFragenFormGroup,
      [CAPTCHA_SOLUTION_DATA]: captchaFormGroup,
    });


    this.subscriptions.add(
      this.schadenMeldungFormGroup.statusChanges.pipe(
        distinctUntilChanged(),
      ).subscribe((status) => {
        const elSendenStep = this.sendenStep.nativeElement as HTMLElement;//  hiddenSteps.item(0) as HTMLElement;

        if (status === VALID && !this.validOnce) {
          this.validOnce = true;
          elSendenStep.classList.remove("hidden");
          setTimeout(() => {
            elSendenStep.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 150);
        }
      })
    );
  }

  private getAnsprechpartnerDatenFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansprechpartner: [null, [Validators.required]],
    });
  }

  private getDatenschutzFormGroup(): DynamicFormGroup {
    return new DynamicFormGroup(this.datenschutzConfig);
  }

  public openWarnDialog() {
    this.optionDialogService
      .getConfigBuilder()
      .withTitle("schaden.schadenmeldung.formular.schadentyp.change.title")
      .withMessage("schaden.schadenmeldung.formular.schadentyp.change.msg")
      .withOptionNo()
      .withOptionYes(null, () => {
        this.sendenStep.nativeElement.classList.add("hidden");
        this.selectedSchadenTyp = null;
        this.setSchadenTyp(null);
        this.activateIndicatorStep(null);
      })
      .withDimensions("30%", "auto", "325px")
      .openOptionsDialog();
  }

  public onSubmit() {
    this.openLoadingSpinner();
    this.activateIndicatorStep(7);

    /** The server expects an instance of {@link SchadenmeldungDto } */
    const formData: SchadenmeldungDto = {
      ...this.schadenMeldungFormGroup.value,
      beschreibung: { beschreibung: this.getBeschreibung() } as BeschreibungDto,
      dezentraleSchadenmeldungUuId: this.dezentralUuid,
    };
    // Overwrite some common properties
    formData.allgemeineDaten = {
      ...formData.allgemeineDaten,
      schadenDatum: this.getSchadenDatum(),
      schadenTyp: this.selSchadenTypId
    };

    // In order not to break the server's field validation logic we also have to send the values of the damage art (if
    // any). It is important that the values are associated with the constant SCHADEN_ART_IDS since this is the name
    // expected by the server-side validation logic. Note that if the user selects a damage type (SchadenTyp) the value
    // of this property will be null. A damage art (SchadenArt) is associated with a damage type (SchadenTyp) and not
    // vice versa.
    formData.spezifischeDaten = {
      ...formData.spezifischeDaten,
      [SCHADEN_ART_IDS]: this.getSchadenArtIds()
    };
    this.schadenmeldungService.sendSchadenmeldung(formData, this.getFiles()).subscribe({
      next: (response: SchadenmeldungAntwortDto) => this.handleSendingResponse(response),
      error: (resp) => this.handleError(resp),
    });
  }

  /**
   * Retrieves the files attached to the damage report.
   *
   * @return A collection of files attached to the damage report
   */
  private getFiles(): File[] {
    return this.getUnterlagenCtl().get("files").value;
  }

  /**
   * Retrieves the value of the property {@link SCHADEN_ART_IDS}. The value is updated by either selecting  a particular
   * damage art (within a group) or by selecting a specialized vehicle damage art (KFZ-Schadenart).
   *
   * @return A collection of damage art (SchadenArt) ids
   * */
  private getSchadenArtIds(): string[] | null {
    const formData = this.schadenMeldungFormGroup.value;
    if (formData.spezifischeDaten.schadenArtIds) {
      return formData.spezifischeDaten.schadenArtIds;
    }
    return this.selSchadenArtId ? [this.selSchadenArtId] : null;
  }

  // Schadenmeldung wurde erfolgreich gesendet Event
  private handleSendingResponse(response: SchadenmeldungAntwortDto) {
    this.closeLoadingSpinner();
    this.sendingResponse.emit(response);
  }

  private handleError(error) {
    this.closeLoadingSpinner();
    if (error && error.error && error.error.showCaptcha) {
      this.loadCaptcha(error.error.showCaptcha);
    } else {
      this.errorService.handleError(error);
    }
  }

  // Captcha Aufforderung einblenden und auf required setzen
  public loadCaptcha(showCaptcha) {
    this.captchaRequired = showCaptcha;
    // TODO: Handle error
    this.captchaService.newChallenge().subscribe({
      next: (captchaChallenge) => this.processCaptcha(captchaChallenge),
    });
  }

  private processCaptcha(captchaChallenge: CaptchaDto) {
    const captchaFormData = this.schadenMeldungFormGroup.get(CAPTCHA_SOLUTION_DATA);
    captchaFormData.enable();
    captchaFormData.get(CAPTCHAREQUESTID).setValue(captchaChallenge.id);
    captchaFormData.get(TOKEN).setValue(captchaChallenge.token);
    captchaFormData.get(PROPOSEDSOLUTION).setValue(null);
    this.captchaImagePath = this.sanitizer.bypassSecurityTrustResourceUrl(captchaChallenge.imageData);
  }

  private openLoadingSpinner(): void {
    this.dialog.open(LoadingDialogComponent, {
      width: "400px",
      disableClose: true,
      autoFocus: false,
      data: { title: this.translate.instant("schadenmeldung.send.text") },
    });
  }

  private closeLoadingSpinner(): void {
    this.dialog.closeAll();
  }

  //The length of this number is exact 10 digits
  private getKundenNummerValidator(): ValidatorFn {
    return Validators.pattern("[0-9]{10}");
  }

  private getKundenNummerValidators(): ValidatorFn[] {
    return [Validators.minLength(10), Validators.minLength(10)];
  }

  private isBlank(val: string | null | undefined): boolean {
    return val === null || val === undefined || (typeof val === "string" && val.trim().length === 0);
  }

  private getVersicherungsNehmerValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: IAdresse[] = control?.value;
      let everyValid = false;
      if (value) {
        everyValid = value.every((contact: IAdresse) => {
          const { name, strasse, plz, ort } = contact;
          return !this.isBlank(name) && !this.isBlank(strasse) && !this.isBlank(plz) && !this.isBlank(ort);
        });
      }
      return everyValid ? null : { [VERSICHERUNGS_NEHMER]: true } as ValidationErrors;
    };
  }

  public isVersicherungsNehmerCompleted(): boolean {
    const kontaktDaten: FormControl<IAdresse[]> = this.getVersicherungsnehmerCtrl();
    const kundenNummer: FormControl<string> = this.getKundenNummerCtrl();
    return kontaktDaten.valid && kundenNummer.valid;
  }

  public isSpezifischeDatenCompleted(): boolean {
    const control = this.schadenMeldungFormGroup.get(SPEZIFISCHE_DATEN);
    return control === null || (control.valid && this.isAllgemeineFragenCompleted());
  }

  hasSpezifischeDatenError(): boolean {
    const control = this.schadenMeldungFormGroup.get(SPEZIFISCHE_DATEN);
    return control !== null && !control.valid;
  }

  isAnsprechpartnerDatenCompleted(): boolean {
    const control = this.schadenMeldungFormGroup.get(ANSPRECHPARTNER_DATEN);
    return control === null || control.valid;
  }

  isTenantfieldDatenCompleted(): boolean {
    const control = this.schadenMeldungFormGroup.get(TENANT_FELDER);
    return control === null || control.valid;
  }

  hasTenantFieldsDatenError(): boolean {
    const control = this.schadenMeldungFormGroup.get(TENANT_FELDER);
    return control !== null && !control.valid;
  }

  hasAnsprechpartnerDatenError(): boolean {
    const control = this.schadenMeldungFormGroup.get(ANSPRECHPARTNER_DATEN);
    return control !== null && !control.valid;
  }

  isDatenschutzSeiteCompleted(): boolean {
    const control = this.schadenMeldungFormGroup.get(DATENSCHUTZSEITE);
    return control === null || control.valid;
  }

  hasDatenschutzSeiteError(): boolean {
    const control = this.schadenMeldungFormGroup.get(DATENSCHUTZSEITE);
    return control !== null && !control.valid;
  }

  isBeschreibungCompleted(): boolean {
    const control = this.schadenMeldungFormGroup.get(BESCHREIBUNG);
    return control === null || control.valid;
  }

  hasBeschreibungError(): boolean {
    const control = this.schadenMeldungFormGroup.get(BESCHREIBUNG);
    return control !== null && !control.valid;
  }

  isBankdatenCompleted(): boolean {
    const control = this.schadenMeldungFormGroup.get(BANKDATEN);
    return control === null || control.valid;
  }

  hasBankdatenError(): boolean {
    const control = this.schadenMeldungFormGroup.get(BANKDATEN);
    return control !== null && control.valid;
  }

  // This method provides a single mean of referencing a component (a form group). Referencing a component using its
  // hard-coded string name is not a good idea.
  get spezielleFragenFormGroup(): DynamicFormGroup | null {
    return this.spezifischeDatenGroup$.value;
  }

  get isArztHaftpflicht(): boolean {
    return this.schadenMeldungFormGroup.get(ALLGEMEINE_DATEN).get(SCHADEN_TYP).value === "11";
  }

  public getVersicherungsnehmerCtrl(): FormControl<IAdresse[]> {
    if (this.versNehmerCtrl === null) {
      this.versNehmerCtrl = new FormControl<IAdresse[]>(null);
      this.versNehmerCtrl.addValidators([this.getVersicherungsNehmerValidator()]);
      this.versNehmerCtrl.updateValueAndValidity();
    }
    return this.versNehmerCtrl;
  }

  public getKundenNummerCtrl(): FormControl<string> {
    if (this.kundenNummerCtrl === null) {
      this.kundenNummerCtrl = new FormControl<string>(null);
      this.kundenNummerCtrl.addValidators([this.getKundenNummerValidator()]);
      this.kundenNummerCtrl.updateValueAndValidity();
    }
    return this.kundenNummerCtrl;
  }

  private isAllgemeineFragenCompleted(): boolean {
    return this.allgemeineFragenFormGroup === null || this.allgemeineFragenFormGroup.valid;
  }

  private getSchadenDatumFormCtrl(): FormControl {
    return this.allgemeineFragenFormGroup.get("schadenDatum") as FormControl;
  }

  private getUnterlagenCtl(): FormGroup<Unterlagen> {
    if (this.unterlagenFormGroup === null) {
      this.unterlagenFormGroup = new FormGroup<Unterlagen>({
        files: new FormControl<File[] | null>(null)
      });
      this.subscriptions.add(
        this.unterlagenFormGroup.get("files").valueChanges.subscribe((files: File[]) => {
          this.filesLength = files ? files.length : 0;
        })
      );
    }
    return this.unterlagenFormGroup;
  }

  // Sync with Java code!
  visibilityConditionEvaluator = (condition: AbstractFieldVisibilityConditionUnion, includePrefix: boolean): Observable<boolean> => {

    const conditionType: ConditionType = condition.conditionType;
    if (conditionType === "always") {
      return of(true);
    } else if (condition.conditionType === "not") {
      return this.visibilityConditionEvaluator(condition.condition, includePrefix).pipe(
        map((result) => !result)
      );
    } else if (condition.conditionType === "schadentyp") {
      const schadenTypeVisibility: FieldVisibilityConditionSchadentypDto = condition;
      return this.schadenTypen$.pipe(
        mergeMap((value: IdBezeichnungWithRefDto) => {
          const selSchadenTypId: string | null = this.getSelSchadenTypId(value);
          return selSchadenTypId ? of(schadenTypeVisibility.typen.includes(selSchadenTypId)) : of(false);
        })
      );
    } else if (condition.conditionType === "schadenart") {
      const schadenArtVisibility: FieldVisibilityConditionSchadenartDto = condition;
      return this.schadenTypen$.pipe(
        mergeMap((schadenTyp: IdBezeichnungWithRefDto) => {
          if (schadenTyp?.id === schadenArtVisibility.typ) {
            return this.spezifischeDatenValue$.pipe(
              map((currentValues: Record<string, unknown>) => {
                // schadenArtIds may be missing if spezifischeDaten step was not initialized yet
                const schadenArtIds: string[] = (currentValues.schadenArtIds as string[]) ?? [];
                return schadenArtVisibility.arten.some((artId: string) => schadenArtIds.includes(artId));
              })
            );
          } else {
            return of(true);
          }
        })
      );
    } else if (condition.conditionType === "hasValue") {
      const stringValue$: Observable<string[]> = includePrefix
        ? this.getTechnicalStringValue(condition.otherFieldKey, "TENANTFELDER")
        : this.getTechnicalStringValue(condition.otherFieldKey, "SPEZIFISCHEDATEN");
      return stringValue$.pipe(
        map((stringValue: string[]) =>
          condition.valueThatKeyNeeds.some((val: string) => stringValue.includes(val))
        )
      );
    } else {
      // Ignore the condition
      return of(true);
    }
  };

  /**
   * Where to send the claim to
   */
  private getTargetFormGroup() {
    const target = this.allgemeineDaten.target;
    if (!target) {
      // Cannot be edited by the user, but will set the property to null in the JSON.
      return new UntypedFormControl(null);
    } else {
      // The type DTOs all have the same shape, so we can use this short hack to generate the form.
      return new UntypedFormGroup({
        type: new UntypedFormControl(target.type),
        [target.type + "Id"]: new UntypedFormControl(null, Validators.required),
      });
    }
  }


  private getTechnicalStringValue(fieldKey: string, group: string): Observable<string[]> {
    switch (group) {
    case "SPEZIFISCHEDATEN": {
      return this.spezifischeDatenValue$.pipe(
        map((fieldValuesByKey: FormGroupValue) => this.inner(fieldKey, fieldValuesByKey, this.spezielleFragenConfig)),
        distinctUntilChanged()
      );
    }
    case "TENANTFELDER": {
      return this.tenantFragenValue$.pipe(
        map((json) => this.inner(fieldKey, json, this.tenantFieldsConfig)),
        distinctUntilChanged()
      );
    }
    }
  }

  /**
   * Retrieves the string values from <i>valuesByKey</i> object associated with key and converted by the rules derived from
   * the meta information provided by configs.
   *
   * @param key - The identifier of the value.
   * @param valuesByKey - An object comprised of key-value pairs. The current value of a FormGroup.
   * @param configs - A collection of meta information items describing form fields.
   * @return - The string values associated with key.
   */
  private inner(key: string, valuesByKey: FormGroupValue, configs: FieldConfig[]): string[] {
    if (!(key in valuesByKey)) {
      // This may happen if the spezifische daten step was not initialized
      // In this case, just return a fake value to avoid the client throwing errors
      // This method will be re-called once it was initialized and set up.
      return [];
    }
    // Sync with Java code!
    const value = valuesByKey[key];
    const config: FieldConfig = configs.find((conf: FieldConfig) => conf.key === key);
    if (!config) {
      throw new Error("Got a visibility request for field " + key + " but that field is not present in the field list.");
    }
    const isMultipleSel: boolean = (config.inputType === "list" || config.inputType === "combobox") && config.multiple;
    if (isMultipleSel && Array.isArray(value)) {
      return value;
    } else if (config.inputType === "checkbox") {
      return [value ? "true" : "false"];
    } else if (config.inputType === "input_numeric" && typeof value === "number") {
      return [value.toString()];
    } else if (config.inputType === "combobox" || config.inputType === "list" && typeof value === "string") {
      return [`${value}`];
    } else {
      return [];
    }
  }

  private setIbanValidation() {
    const regExp = /[^a-zA-Z0-9]/g;

    this.schadenMeldungFormGroup
      .get("bankdaten")
      .get("iban")
      .setValidators([ValidatorService.validateIban]);

    this.subscriptions.add(this.schadenMeldungFormGroup
      .get("bankdaten")
      .get("iban").valueChanges.subscribe(value => {

        if(value && regExp.test(value)) {
          const changedValue = value.replace(regExp, "");
          this.schadenMeldungFormGroup
            .get("bankdaten")
            .get("iban").setValue(changedValue);
        }

      }));
  }

  private getSchadenDatum(): string {
    return this.getSchadenDatumFormCtrl().value;
  }

  private getBeschreibung(): string {
    return this.spezifischeDatenGroup$.value.get("beschreibung").value;
  }

  /**
   * Within the view 'selSchadenTyp' is used as an object containing 'id' and 'foreignId'. However, this object
   * must be remapped back to its 'id' when interacting with the server since it only accepts 'id' as a string and
   * cannot process the complete 'schadenTyp' object. This was done for backward compatibility. Future server
   * implementations can allow the processing of a 'schadenTyp' object
   * */
  private getSelSchadenTypId(value: IdBezeichnungWithRefDto): string | null {
    if (value !== null) {
      return value.foreignId ? value.foreignId : value.id;
    }
    return null;
  }

  private getSelSchadenArtId(value: IdBezeichnungWithRefDto): string | null {
    if (value !== null) {
      return value.foreignId ? value.id : null;
    }
    return null;
  }
}
