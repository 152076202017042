import { Component, Input, OnInit } from "@angular/core";
import { FieldConfigBigDecimalInputDto } from "connect-frontend-service";
import { UntypedFormGroup } from "@angular/forms";
import { CurrencyPipe, registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";

@Component({
  selector: "lib-field-input-big-decimal",
  templateUrl: "./field-input-big-decimal.component.html",
  styleUrls: ["./field-input-big-decimal.component.scss"]
})
export class FieldInputBigDecimalComponent implements OnInit {

  @Input()
  public config: FieldConfigBigDecimalInputDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor(private pipe: CurrencyPipe) {
    registerLocaleData(localeDe, "de");
  }

  ngOnInit(): void {
  }

  /**
   * Checks if the input value is acceptable. If it is, the formatted value is returned. Otherwise `null` is returned.
   */
  checkValue(): string | null {
    const ctl = this.formGroup.get(this.config.key);
    let value: string;

    if(new RegExp("^(-?\\d{0,3}(\\.\\d{3})*(,\\d{0,2}){0,1})$|^(-?\\d*(,\\d{0,2}){0,1})$").test(ctl.value) && ctl.value !== null) {
      value = ctl.value.replace(new RegExp("\\.", "g"), "").replace(new RegExp(",", "g"), ".");
    } else if (ctl.value !== null) {
      ctl.setErrors({
        format: true
      });
      return null;
    } else {
      return null;
    }

    try {
      const text = this.pipe.transform(value, "EUR", "", "0.2-2", "de");
      if(text.includes("+")) {
        ctl.setErrors({ maxerror: true });
        return null;
      } else {
        return text;
      }
    } catch (e) {
      ctl.setErrors({
        format: true
      });
      return null;
    }
  }

  formatValue() {
    const checkedValue = this.checkValue();
    if (checkedValue !== null) {
      this.formGroup.get(this.config.key).setValue(checkedValue);
    }
  }
}
