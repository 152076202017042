<mat-form-field [formGroup]="formGroup" appearance="outline">
  <input  matInput [formControlName]="config.key" (change)="formatValue()" [required]="config.required"/>
  <mat-icon *ngIf="config.icon" matSuffix>{{config.icon}}</mat-icon>
  <mat-label style="margin-bottom: 12px">
    {{ config.label }}
  </mat-label>
  <mat-error *ngIf="formGroup.get(config.key).getError('format')">
    {{ "app.base.form.bigdecimal.format" | translate }}
  </mat-error>
  <mat-error *ngIf="formGroup.get(config.key).getError('maxerror')">
    {{ "app.base.form.bigdecimal.maxerror" | translate }}
  </mat-error>
</mat-form-field>
