<h4 *ngIf="config.title" class="fieldtitle">{{ config.title }}</h4>
<mat-form-field appearance="outline" class="cursor-pointer" [formGroup]="formGroup">
  <mat-label>{{ config.label }}</mat-label>
  <lib-date-picker-field [formControlName]="config.key" [max]="maxDate" [min]="minDate" [required]="config.required"></lib-date-picker-field>
  <mat-error *ngIf="formGroup.get(config.key).getError('datebelowmin')">
    {{ 'schadenmeldung.error_mindate' | translate }}
  </mat-error>
  <mat-error *ngIf="formGroup.get(config.key).getError('dateabovemax')">
    {{ 'schadenmeldung.error_maxdate' | translate }}
  </mat-error>
  <mat-error *ngIf="formGroup.get(config.key).errors?.maxdate || formGroup.get(config.key).errors?.mindate">
    {{ 'schadenmeldung.error_daterange' | translate }}
  </mat-error>
</mat-form-field>
